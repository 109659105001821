import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container" id="containerabout">
      <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Schedule</h2>
          </div>
      
        
      </div>
          <div class="services-box">
            <img
              src="img/portfolio/schedule.png"
              alt="Service Image"
              class="service-img"
              id="about-imgf"
            />
          </div>
    </div>
  );
};
