import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container" id="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Timings</h2>
        </div>
     
      </div>
      <div class="services-box">
          <img
            src="img/portfolio/timings.png"
            alt="Service Image"
            class="service-img"
         id="service-imgf" />
        </div>
    </div>
    
  );
};
